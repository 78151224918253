import React from "react"
import { graphql, Link } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import Layout from "../components/layout/layout"
import PageTransition from "../components/transition/page"
import SEO from "../components/seo/seo"
import Thumbnail from "../components/thumbnail/thumbnail"
import VideoPlayer from "../components/player/video"

export const pageQuery = graphql`
query videoQuery($slug: String! $locale: String!){
  contentfulVideo(slug:{eq:$slug}){
    title
    description
    seo {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    image{
      file{
        url
      }
    }
    video{
      file{
        url
      }
    }
  }
  featured: allContentfulVideo(limit:4 filter:{visible:{eq:true}, node_locale:{eq:$locale}, slug:{ne:$slug}}) {
    videos:edges {
      video:node {
        id
        title
        createdAt
        slug
        description
        image{
          file{
            url
          }
        }
        video{
          file{
            url
          }
        }
      }
    }
  }
  videos: allContentfulVideo(limit: 12, filter: {visible:{eq:true}, node_locale: {eq: $locale} slug:{ne: $slug}}) {
    videos:edges {
      video:node {
        id
        title
        createdAt
        slug
        description
        image{
          file{
            url
          }
        }
        video{
          file{
            url
          }
        }
      }
    }
  }
}
`

class VideoTemplate extends React.Component {

    constructor(props){
      super(props)
      this.pageTransition = React.createRef()
    }

    animate(){
      this.pageTransition.current.play()
    }

    componentDidMount(){
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
          const Plyr = require('plyr');
          this.player = new Plyr(document.getElementById('player'))
        }
    }

    render(){

        const locale = this.props.pageContext.locale
        const video = this.props.data.contentfulVideo
        const upNextVideos = this.props.data.featured.videos.slice(1)
        const videos = this.props.data.videos.videos

        return (
            <Layout theme="dark" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
              <SEO
                title={video.seo.title}
                description={video.seo.description}
                keywords={video.seo.keywords && video.seo.keywords.keywords}
                image={video.seo.image.file.url}
              />
              <div className={"w-full bg-gray-900"}>
                  <section className={"container mx-auto px-0 lg:px-8"}>
                      <VideoPlayer source={video.video.file.url} autoPlay={true} id={"video-page"} />
                      <div className={"py-4 mt-4 mb-12 px-8 lg:px-0"}>
                          <h3 className={"font-agrandir text-white text-xl"}>{video.title}</h3>
                          <span className={"font-montserrat-regular text-gray-700"}>{video.description}</span>
                      </div>
                  </section>
                  <section className={"container mx-auto py-4"}>
                    <p className={"uppercase text-white text-lg font-formula-light tracking-widest pl-8 mb-6"}>featured videos</p>
                    <div className={"flex flex-wrap mx-auto px-4"}>
                      {
                          upNextVideos.map((item, index) => {
                              return(
                                <div className={"w-full md:w-1/2 lg:w-1/3 px-4"} key={index}>
                                  <Link to={`/${locale}/tv/${item.video.slug}`}>
                                    <Thumbnail 
                                      title={item.video.title} 
                                      description={item.video.description} 
                                      image={item.video.image.file.url}
                                      theme={"dark"}
                                      type={"minimal"}
                                      video={true}
                                    />
                                  </Link>
                                </div>
                              )
                          })
                      }
                      </div>
                  </section>
                  <section className={"container mx-auto py-4 my-4"}>
                      <p className={"uppercase text-white text-lg font-formula-light tracking-widest pl-8 mb-6"}>all videos</p>
                      <div className={"flex flex-wrap px-4"}>
                      {
                          videos.map((item, index) => {
                              return(
                                <div className={"w-full md:w-1/2 lg:w-1/3 px-4"} key={index}>
                                  <Link to={`/${locale}/tv/${item.video.slug}`}>
                                    <Thumbnail 
                                      title={item.video.title} 
                                      description={item.video.description} 
                                      image={item.video.image.file.url}
                                      theme={"dark"}
                                      type={"minimal"}
                                      video={true}
                                    />
                                  </Link>
                                </div>
                              )
                          })
                      }
                      </div>
                  </section>
              </div>
              <TransitionPortal>
                <PageTransition ref={this.pageTransition} />
              </TransitionPortal>
          </Layout>
        )
    }

}

export default VideoTemplate
